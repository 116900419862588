import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  alignSelf, borderColor, borderRadius, borders, color, fontSize, space, textAlign, width, fontWeight
} from 'styled-system';

export const StyledButton = styled.button`
  border-radius: 2px;
  ${alignSelf}
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${textAlign}
  ${borderColor}
  ${borderRadius}
  ${borders}
  ${fontWeight}
`;

const Button = ({
  children, primary, inverted, loading, outlined, className, text, ...props
}) => (
  <StyledButton
    className={`button ${primary ? 'is-primary' : ''} ${inverted ? 'is-inverted' : ''} ${loading ? 'is-loading' : ''} ${outlined ? 'is-outlined' : ''} ${className || ''}`}
    fontSize={[1, 1, 2]}
    {...props}
  >
    {!text && !children && 'Submit'}
    {!text && children}
    {text && text === 'ok' && <FormattedMessage id="common:button.ok" defaultMessage="OK" />}
    {text && text === 'submit' && <FormattedMessage id="common:button.submit" defaultMessage="Submit" />}
    {text && text === 'back' && <FormattedMessage id="common:button.back" defaultMessage="Back" />}
    {text && text === 'next' && <FormattedMessage id="common:button.next" defaultMessage="Next" />}
    {text && text === 'viewAll' && <FormattedMessage id="common:button.viewAll" defaultMessage="View All" />}
    {text && text === 'login' && <FormattedMessage id="common:button.login" defaultMessage="Log in" />}
    {text && text === 'readMore' && <FormattedMessage id="common:button.readMore" defaultMessage="Read More" />}
    {text && text === 'cancel' && <FormattedMessage id="common:button.cancel" defaultMessage="Cancel" />}
    {text && text === 'details' && <FormattedMessage id="common:button.details" defaultMessage="Details" />}
    {text && text === 'close' && <FormattedMessage id="common:button.close" defaultMessage="Close" />}
  </StyledButton>
);

export default Button;
