import React, { Component } from 'react';

import get from 'lodash/get';
import Section from '../Atoms/Section';
import theme from '../../styles/theme';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';

class ContactHeader extends Component {
  render() {
    const { title, subtitle, bgImage } = this.props;

    return (
      <Section
        pt={8}
        pb={6}
        backgroundColor={theme.color.blue}
        height={300}
        background={`url(${get(bgImage, 'image.childImageSharp.fluid.src', get(bgImage, 'image', ''))})`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <Container>
          <Row>
            <Column>
              <Heading h={4} color={theme.color.white}>
                {subtitle}
              </Heading>
              <Heading h={1} color={theme.color.white}>
                {title}
              </Heading>
            </Column>
          </Row>
          <Row />
        </Container>
      </Section>
    );
  }
}

export default ContactHeader;
