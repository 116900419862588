import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

import Paragraph from '../Atoms/Paragraph';
import { makeRequest } from '../../utilis/helpers';
import Button from '../Atoms/Button';
import theme from '../../styles/theme';

class ContactForm extends Component {
  commonError = null;

  submitForm = async (values, actions) => {
    const form = new FormData();
    form.append('user', values.user_name);
    form.append('email', values.email);
    form.append('reason', values.reason);
    form.append('message', values.message);

    try {
      const res = await makeRequest({
        data: form,
      });
      actions.setSubmitting(false);
      actions.setStatus({ submitted: 'Thank you for your enquiry. We will reply you as soon as possible.' });
    } catch (e) {
      actions.setSubmitting(false);
      actions.setErrors({ common: 'An error occurs. Please try again later.' });
    }
  };

  render() {
    const { reasons } = this.props;

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          user: '',
          email: '',
          reason: '',
          message: '',
        }}
        validate={(values, props) => {
          this.commonError = null;
          const errors = {};
          if (!values.user_name) {
            errors.user_name = 'This field is required';
          }
          if (!values.email) {
            errors.email = 'This field is required';
          }
          if (!values.reason) {
            errors.reason = 'This field is required';
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          this.submitForm(values, actions);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setStatus,
          setFieldValue,
          isSubmitting,
          isValid,
          setSubmitting,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.name" defaultMessage="Name" />
              </label>
              <div className="control">
                <input
                  name="user_name"
                  className="input"
                  type="text"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.user_name && (
                <p className="help is-danger">{errors.user_name}</p>
              )}
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.email" defaultMessage="Email Address" />
              </label>
              <div className="control">
                <input
                  name="email"
                  className="input"
                  type="email"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email && (
                <p className="help is-danger">{errors.email}</p>
              )}
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.reason" defaultMessage="Contact Reason" />
              </label>
              <div className="control">
                <div className="select">
                  <select
                    name="reason"
                    defaultValue=""
                    onChange={(e) => {
                      setErrors({});
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled>
                      {reasons.placeholder}
                    </option>
                    {reasons.list && reasons.list.map(reason => (
                      <option key={reason.value} value={reason.value}>{reason.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              {errors.reason && (
                <p className="help is-danger">{errors.reason}</p>
              )}
            </div>
            <div className="field">
              <label className="label">
                <FormattedMessage id="contact:form.message" defaultMessage="Message" />
              </label>
              <div className="control">
                <textarea
                  name="message"
                  className="textarea"
                  placeholder="Type your message here"
                  onChange={(e) => {
                    setErrors({});
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              {errors.message && (
                <p className="help is-danger">{errors.message}</p>
              )}
            </div>
            <Paragraph fontSize={1}>
              <FormattedMessage id="contact:form.remark" defaultMessage="Primech Holdings is committed to protecting your information. Your information will be used in accordance with the applicable data privacy, our internal policies and our privacy policy." />
            </Paragraph>
            {status && status.submitted && (
              <Paragraph fontSize={1} color={theme.color.blue}>
                {status.submitted}
              </Paragraph>
            )}
            {errors.common && (
              <Paragraph fontSize={1} color={theme.color.red}>
                {errors.common}
              </Paragraph>
            )}
            <div className="control">
              <Button
                primary
                type="submit"
                text="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              />
            </div>
          </form>
        )}
      />
    );
  }
}

export default ContactForm;
