import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { graphql } from 'gatsby';
import Section from '../../components/Atoms/Section';
import Container from '../../components/Atoms/Container';
import theme from '../../styles/theme';
import Row from '../../components/Atoms/Row';
import Column from '../../components/Atoms/Column';
import Paragraph from '../../components/Atoms/Paragraph';
import Box from '../../components/Atoms/Box';
import ContactHeader from '../../components/Contact/ContactHeader';
import Heading from '../../components/Atoms/Heading';
import ContactForm from '../../components/Contact/ContactForm';
import { PageHelmet } from '../../components/PageHelmet';

const ColumnWithShadow = styled(Column)`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
`;

function Index({ data }) {
  const detail = get(data, 'allMarkdownRemark.edges.0.node.frontmatter', {});
  const address1Arr = detail.address1.split(/\r?\n/);
  return (
    <>
      <PageHelmet title={detail.title} />
      <ContactHeader bgImage={detail.bgImage} />
      <Section pb={7}>
        <Container>
          <Row justifyContent="center">
            <ColumnWithShadow background={theme.color.white} mt={-48} col="is-12">
              <Row pt={2}>
                <Column>
                  <Heading h={2} fontSize={[4, 4, 6]}>
                    <FormattedMessage id="contact:intro:title" defaultMessage="JOIN US AT THE INDUSTRY’S FRONTIER." />
                  </Heading>
                  <Paragraph>
                    <FormattedMessage
                      id="contact:intro:content"
                      defaultMessage="To learn more about our portfolio, or provide feedback on our services, please get in touch via the following channels."
                    />
                  </Paragraph>
                  <Heading h={3} pb={3}>
                    <FormattedMessage id="contact:general" defaultMessage="General Enquiry" />
                  </Heading>
                  <Paragraph fontWeight={700}>
                    <FormattedMessage id="contact:tel" defaultMessage="Tel:" />
                    <Box as="span" fontWeight={400} ml={1}>
                      {detail.tel}
                    </Box>
                  </Paragraph>
                  <Paragraph fontWeight={700}>
                    <FormattedMessage id="contact:email" defaultMessage="Email:" />
                    <Box as="span" fontWeight={400} ml={1}>
                      <a href="mailto:enquiries@primech.com.sg">enquiries@primech.com.sg</a>
                      { /* detail.email */ }
                    </Box>
                  </Paragraph>
                  <Heading h={3} pb={3} pt={5}>
                    <FormattedMessage id="contact:investor" defaultMessage="Investor Relations" />
                  </Heading>
                  <Paragraph fontWeight={700}>
                    <FormattedMessage id="contact:iremail" defaultMessage="Email:" />
                    <Box as="span" fontWeight={400} ml={1}>
                      <a href="mailto:ir@primech.com.sg">ir@primech.com.sg</a>
                      { /* detail.iremail */}
                    </Box>
                  </Paragraph>
                  <Paragraph fontWeight={700} pb={1} pt={5}>
                    <FormattedMessage id="contact:address" defaultMessage="Address:" />
                  </Paragraph>
                  {address1Arr && address1Arr.map(add => (
                    <Paragraph pb={0} key={add}>
                      <Box as="span" fontWeight={400}>
                        {add}
                      </Box>
                    </Paragraph>
                  ))}
                </Column>
                <Column>
                  <ContactForm reasons={detail.contactReasons || []} />
                </Column>
              </Row>
            </ColumnWithShadow>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default Index;

export const pageQuery = graphql`
  query ContactPage {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          component: { eq: "contact" }
        }
      }
      limit: 1
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            bgImage {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            tel
            email
            address1
            contactReasons {
              placeholder
              list {
                label
                value  
              }
            }
            iremail
          }
        }
      }
    }
  }
`;
