const startCase = require('lodash/startCase');
const axios = require('axios');

const buildArticlePath = ({ slug, group, type }) => {
  const s = slug && slug[0] === '/' ? slug.substr(1) : '';
  const groupType = group === type ? group : `${group}/${type}`;

  if (typeof slug === 'undefined' || slug === '') {
    return groupType;
  }

  return s.replace(group, groupType);
};

const formatTypeString = (type) => {
  if (typeof type !== 'string' || type === '') {
    return type;
  }

  return startCase(type.split('/').pop().replace('_', ' '));
};

const makeRequest = (requestObj) => {
  const config = {
    baseURL: 'https://www.primechholdings.com/', //DEBUG
    responseType: 'json',
    headers: {
      'Content-Type': requestObj.contentType || 'application/x-www-form-urlencoded',
      ...requestObj.headers,
    },
    url: 'api/formHandler',
    data: requestObj.data || {},
    params: requestObj.params || {},
    method: requestObj.method || 'POST',
    timeout: requestObj.timeout || 10000,
  };


  return axios(config)
    .then((it) => {
      console.log(`return from: ${requestObj.endpoint}`);
      return it;
    })
    .then(resp => resp);
};

exports.buildArticlePath = buildArticlePath;
exports.formatTypeString = formatTypeString;
exports.makeRequest = makeRequest;
